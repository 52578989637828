import styled from 'styled-components';
import {
  borderRadius, boxShadow,
  color, fontSize,
  fontWeight, height,
  maxHeight, maxWidth, minHeight, minWidth, space, width
} from 'styled-system';

const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 14px;
  flex: 0 0 14px;
  @media screen and (max-width: 970px) {
    display: none;
  }
  & img {
    margin-left: 0px;
  }
`;

const TooltipStyle = styled.span`
  display: flex;
  z-index: 1000;
  position: relative;
  width: 14px;
  flex: 0 0 14px;

  .tooltip-top,
  .tooltip-bottom {
    left: 50%;
    transform: translateX(-50%);

    &::after {
      left: 50%;
      transform: translateX(-50%);
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
    }
  }

  .tooltip-top {
    bottom: 100%;
    padding-bottom: 9px;

    &::after {
      bottom: 0;
    }
  }

  .tooltip-bottom {
    top: 100%;
    left: 100%;
    padding-top: 9px;
    padding-left: 13px;

    &::after {
      top: 0;
    }
  }

  .tooltip-left,
  .tooltip-right {
    top: 50%;
    transform: translateY(-50%);

    &::after {
      top: 50%;
      transform: translateY(-50%);
      border-top: 9px solid transparent;
      border-bottom: 9px solid transparent;
    }
  }

  .tooltip-left {
    right: 100%;
    padding-right: 9px;

    &::after {
      right: 0;
    }
  }

  .tooltip-right {
    left: 100%;
    padding-left: 9px;

    &::after {
      left: 0;
    }
  }
`;

const TriggerStyle = styled.span`
  display: inline-block;
  font-weight: 700;
  ${fontSize}
  ${fontWeight}
  ${color}
  ${space}
`;

const BubbleSize = styled.div`
  min-width: 120px;
  max-width: 280px;
  position: absolute;
  z-index: 10000000;
  &::after {
    content: '';
    position: absolute;
  }
  ${minWidth}
  ${maxWidth}
  ${width}
  ${minHeight}
  ${maxHeight}
  ${height}
`;

const BubbleStyle = styled.div`
  border-radius: 3px;
  font-size: 12px;
  line-height: 15px;
  padding: 0.75em;
  text-align: center;

  /* Style system custom style */
  ${boxShadow}
  ${fontSize}
  ${color}
  ${borderRadius}
`;

TooltipStyle.displayName = 'TooltipStyle';

export { TooltipStyle, TriggerStyle, BubbleSize, BubbleStyle, TooltipWrapper };
