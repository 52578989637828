import { gql } from '@apollo/client';

export const listFarmsQuery = gql`
  query listFarms {
    listFarms (limit: 999) {
      items {
        poolId
        farmPoolId
        farmName
        searchName
        shortLpName
        lpName
        lpAddress
        displayName
        exchange
        isExternal
        isGeneric
        isIchiPool
        isMigrating
        isRetired
        isUpcoming
        isDeposit
        isPosition
        lpPrice
        tvl
        farmTVL
        totalPoolLP
        weeklyAPY
        monthlyAPY
        yearlyAPY
        dailyAPY
        futureAPY
        launchDate
        chainId
        tokens {
          name
          displayName
          address
          decimals
          reserve
          price
          isBaseToken
        }
        farm {
          farmAddress
          farmId
          farmRewardTokenAddress
          farmRewardTokenDecimals
          farmRewardTokenName
        }
        extras {
          externalText
          externalUrl
          externalButton
          tradeUrl
        }
      }
    }
  }
`;
