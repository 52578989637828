/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { useState } from 'react';
import {
  BubbleSize,
  BubbleStyle, TooltipStyle,
  TriggerStyle
} from './tooltip.style';

type Props = {
  position: 'left' | 'right' | 'top' | 'bottom';
  message: string;
  bubbleSize?: object;
  bubbleStyle?: object;
  triggerStyle?: object;
  className?: string;
};

const Tooltip: React.FC<Props> = (props) => {
  // tooltip local state
  const [state, setState] = useState({
    open: false,
  });

  // Add all classs to an array
  const addAllClasses = ['reusecore__tooltip'];

  // className prop checking
  if (props.className) {
    addAllClasses.push(props.className);
  }

  // hide tooltip on mouse leave
  const hideTooltip = () => {
    setState({ open: false });
  };

  // show tooltip on mouse over
  const showTooltip = () => {
    setState({ open: true });
  };

  return (
    <TooltipStyle
      className={addAllClasses.join(' ')}
      onMouseLeave={hideTooltip}
    >
      {state.open && (
        <BubbleSize
          className={`tooltip-bubble tooltip-${props.position}`}
          {...props.bubbleSize}
        >
          <BubbleStyle className="tooltip-message" {...props.bubbleStyle}>
            {props.message}
          </BubbleStyle>
        </BubbleSize>
      )}
      <TriggerStyle
        className="tooltip-trigger"
        onMouseOver={showTooltip}
        {...props.triggerStyle}
      >
        {props.children}
      </TriggerStyle>
    </TooltipStyle>
  );
};

/** Tooltip default proptype */
Tooltip.defaultProps = {
  position: 'right'
};

export default Tooltip;
